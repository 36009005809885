import { useDispatch, useSelector } from 'react-redux';
import { connect } from '../redux/blockchain/blockchainActions';
import { fetchData } from '../redux/data/dataActions';
import { useState, useEffect } from 'react';
import bgimg from '../assets/bgimg.png';
import EWTlogo from '../assets/EWTlogo.png';
import shellcoin from '../assets/shellcoin.png';
import susucoin from '../assets/susu.png';
import useColorChange from 'use-color-change';
import store from '../redux/store';
import Goldstakers from '../assets/Goldstaker.png'
import ttlogo_eggs from '../assets/ttlogo_eggs.png'
import teamseas from '../assets/teamseaslogosmall.png'
import tttlogo from '../assets/tttlogo.png'

const Home = () => {
    /* global BigInt */
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const bcdata = useSelector((state) => state.data);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        CONTRACT_ADDRESS2: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        }
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    console.log(bcdata)

    const [braveShieldError, setBraveShieldError] = useState(false);
    const [userSHLBalance, setUserSHLBalance] = useState(0);
    const getUserSHLBalance = async () => {
        if (blockchain.account !== "") {
            try {
            const response = await fetch(
                `https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=0xc6c6239614723298591f16bB2f779C9199b5AB1a&address=${String(blockchain.account)}`
            ).then((response) => response.json());
            if (response["result"] !== null && response["result"] !== undefined) {
                console.log(response)
                setUserSHLBalance(BigInt(response["result"])/BigInt(1e+18));
            } 
            } catch {setBraveShieldError(true)}
        }
    };

    const [shlContract1, setShlContract1] = useState(0);
    const getShlContract1 = async () => {
        if (blockchain.account !== "") {
            try {
            const response = await fetch(
                `https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=0xc6c6239614723298591f16bB2f779C9199b5AB1a&address=0x0690C9892e2DfbD6D7AE5c9601483100F0BBCa57`
            ).then((response) => response.json());
            if (response["result"] !== null && response["result"] !== undefined) {
                setShlContract1(BigInt(response["result"])/BigInt(1e+18)/BigInt(10000));
            } 
            } catch {setBraveShieldError(true)}
        }
    };

    useEffect(() => {
        getShlContract1();
    }, [])

    //console.log(userSHLBalance)

    // --------------------------------------------------

    useEffect(() => {
        getUserSHLBalance();
    }, [blockchain.account, CONFIG]);

    async function claimAirdrop() {
        let cost = 0;
        let totalCostWei = String(cost);

        blockchain.smartContract.methods.registerForAirdrop(String(blockchain.account))
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
                gasPrice: 100000000,
            }).then((receipt) => {
                console.log(`Transaction receipt: ${receipt}`)
                if (receipt["blockHash"] !== undefined && receipt["blockHash"] !== "") {
                    setTimeout(function(){
                        getUserSHLBalance();
                    }, 1000);
                }
        });
    }

    const tokenAddress = "0xc6c6239614723298591f16bB2f779C9199b5AB1a";
    const tokenSymbol ='SHL';
    const tokenDecimals = 18;
    const tokenImage = 'https://tubbyturtles.com/shellcoin.png';

    async function addTokenFunction() {

    try {
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', 
                options: {
                    address: tokenAddress, 
                    symbol: tokenSymbol, 
                    decimals: tokenDecimals, 
                    image: tokenImage, 
                },    
            },
        });

        if (wasAdded) {
            console.log('SeaShell (SHL) has been added');
        } else {
            console.log('SHL has not been added');
        }
        } catch (error) {
            console.log(error);
        }
    }



    // -----------------------------------------

    return (
        <div className="flex flex-col mx-auto w-[95%] md:w-[85%] lg:w-[80%] xl:w-[75%] 2xl:w-[70%] 4xl:w-[65%] min-h-[100vh] py-4 sm:py-8 md:py-12 lg:py-16">
            <img className='bgimg fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="the background" />

            <div className='rounded-xl sm:rounded-2xl 3xs:px-[2px] 2xs:px-1 xs:px-2 s:px-4 px-6 sm:px-8 md:px-10 pt-6 sm:pt-8 pb-8 md:pb-16 flex flex-col bg-[rgb(250,250,255)]'>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                <div> {/* Unconnected */}
                <img className='mx-auto mb-1 sm:mb-2 md:mb-3 aspect-square w-[8em] md:w-[10em] lg:w-[12em] rounded-3xl sm:rounded-[2rem] md:rounded-[2.5rem] lg:rounded-[3.0rem]' src={ttlogo_eggs} alt="" />
                    <h1 className='mb-1 sm:mb-2 md:mb-3 text-lg md:text-xl lg:text-2xl text-center'>Welcome to the SeaShell<img className='inline h-6 w-6 ml-1 -translate-y-[2px]' src={shellcoin} alt="" /> airdrop register dapp!</h1>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>Here you can register for an airdrop of 10 000 SHL if you have the gold staker rank in the EWT consortia staking pool!</h2>
                    <div className='bg-[rgb(235,235,255)] rounded-xl p-2 sm:p-4'>
                        <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>What is Tubby Turtles and SeaShell (SHL)?</h2>
                        <img className='aspect-square w-8 sm:w-9 md:w-10 rounded-full mx-auto' src={tttlogo} />
                        <p className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>
                            Tubby Turtles is one of the few NFT projects on Energy Web. 10% of the minting costs of each turtle have been and will be donated to <a href='https://teamseas.org/' rel='noreferrer' className='text-blue-500 hover:text-purple-500'>#teamseas</a> <img className='w-5 h-5 inline' src={teamseas} /> (raised about a combined total of $890 so far).
                            <br/>
                            When you stake a Tubby Turtle you will be rewarded with SeaShell (SHL) tokens, the rarity of your turtle will affect the amount of SHL you earn per day.
                        </p>
                        <img className='aspect-square w-8 sm:w-9 md:w-10 rounded-full mx-auto' src={shellcoin} />
                        <p className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>
                            The SeaShell token is the reward, governance and utility token of the Tubby Turtles project.
                            <br/>
                            The token enables you to stake your turtle and also to change your own turtle's names to personalise them. 
                            <br/>
                            It costs 15 000 SHL to stake a turtle.
                        </p>
                        <p className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>Read more about Tubby Turtles and SeaShell at: <a className='text-blue-500 hover:text-purple-500' href='https://tubbyturtles.com' rel='noreferrer' target='_blank'>Tubby Turtles site</a></p>
                    </div>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>Disclaimer: this airdrop is not associated with the Energy Web Foundation (EWF), this is just a community created airdrop organized by a community created NFT project deployed on EWC.</h2>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>You can only register once for the airdrop, registering multiple times won't get you a second airdrop, also if you don't have the gold staker VC then you won't receive the airdrop.</h2>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>The airdrop can still be claimed by <span className='font-bold'>{String(shlContract1)}</span> more gold stakers.</h2>
                    <h2 className='text-base md:text-lg lg:text-xl text-center'>You can view the verified airdrop register smart contract on the Energy Web explorer: <a 
                        className='text-blue-500 hover:text-purple-500' href='https://explorer.energyweb.org/address/0x024b2539111Cc805490f6188e2CeF83D7E1FE161/contracts#address-tabs' rel='noreferrer' target='_blank'>Airdrop register contract</a>
                    </h2>
                    <button className='text-base md:text-lg lg:text-xl text-center mt-2 mb-6 mx-auto hover:brightness-110 bg-blue-300 flex flex-row px-4 py-2 rounded-xl' onClick={addTokenFunction}>Add SeaShell (SHL) to Metamask<img className='ml-1 w-6 aspect square' src={shellcoin} /></button>
                    <div className='flex items-center justify-center mx-auto gradientAnimate rounded-[2rem] md:rounded-[2.5rem] w-[95%] sm:w-[90%] md:w-[85%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%] 3xl:w-[40%] aspect-[5/5]'>
                        <div className='flex flex-col relative z-[99] py-5 px-3 sm:py-6 sm:px-4 md:py-7 md:px-5'>
                            <div className='flex flex-col'>
                            <div className='flex flex-col mx-auto'>
                                    <h1 className='xs:text-base text-lg md:text-xl lg:text-2xl text-center font-semibold'>Verified credential</h1>
                                    <div className='flex flex-row mx-auto'>
                                        <img className='3xs:h-5 3xs:w-54 2xs:h-6 2xs:w-6 xs:h-7 xs:w-7 s:h-10 s:w-10 h-14 w-14 mx-2' src={Goldstakers} alt="" />
                                    </div>
                                </div>
                                <div className='flex flex-col mx-auto'>
                                    <h1 className='xs:text-base text-lg md:text-xl lg:text-2xl text-center font-semibold'>Airdrop reward</h1>
                                    <h2 className='flex flex-row mx-auto 3xs:text-xs 2xs:text-sm xs:text-base text-lg md:text-xl lg:text-2xl text-center font-semibold'><span>10 000</span><img className='ml-2 3xs:h-4 3xs:w-4 2xs:h-6 2xs:w-6 xs:h-7 xs:w-7 s:h-8 s:w-8 h-9 w-9' src={shellcoin} alt="" /></h2>
                                </div>
                            </div>
                            <h1 className='xs:text-base text-lg md:text-xl lg:text-2xl text-center px-2'>You first have to connect with your Metamask</h1>
                            <button className='mt-4 xs:text-base text-lg md:text-xl flex text-center pl-1 pr-2 mx-auto justify-center
                                            rounded-lg items-center bg-green-500 border-b-[3px] md:border-b-[5px] 
                                            border-[rgb(24,136,65)] hover:brightness-[1.05] md:active:border-b-[3px] md:active:translate-y-[2px] 
                                            active:border-b-[1px] active:translate-y-[2px]' onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(connect());
                                                getData();
                                            }}>
                                            <svg className='w-[35px] h-[35px] sm:w-[40px] sm:h-[40px] md:w-[45px] md:h-[45px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.6 318.6" strokeLinejoin="round">
                                                <path d="M274.1 35.5l-99.5 73.9L193 65.8z" fill="#e2761b" stroke="#e2761b">
                                                </path><g fill="#e4761b" stroke="#e4761b"><path d="M44.4 35.5l98.7 74.6-17.5-44.3zm193.9 171.3l-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="M33.9 207.7L50.1 263l56.7-15.6-26.5-40.6zm69.7-69.5l-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="M214.9 138.2l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5l33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="M138.8 193.5l-28.2-8.3 19.9-9.1zm40.9 0l8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="M87.8 162.1l23.6 46-.8-22.9zm120.3 23.1l-1 22.9 23.7-46zm-64-20.6l-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0l-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                            </svg>
                                            Connect
                                        </button>
                        </div>
                    </div>
                </div>
                ) : (
                <div className='p-2 sm:p-3 md:p-4'> {/* Connected */}
                <img className='mx-auto mb-1 sm:mb-2 md:mb-3 aspect-square w-[8em] md:w-[10em] lg:w-[12em] rounded-3xl sm:rounded-[2rem] md:rounded-[2.5rem] lg:rounded-[3.0rem]' src={ttlogo_eggs} alt="" />
                    <h1 className='mb-1 sm:mb-2 md:mb-3 text-lg md:text-xl lg:text-2xl text-center'>Welcome <span className='bg-[rgba(100,100,240,0.15)] break-words px-1 rounded-md'>{blockchain.account}</span> to the SeaShell<img className='inline h-6 w-6 ml-1 -translate-y-[2px]' src={shellcoin} alt="" /> airdrop register dapp!</h1>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>Here you can register for an airdrop of 10 000 SHL if you have the gold staker rank in the EWT consortia staking pool!</h2>
                    <div className='bg-[rgb(235,235,255)] rounded-xl p-2 sm:p-4'>
                        <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>What is Tubby Turtles and SeaShell (SHL)?</h2>
                        <img className='aspect-square w-8 sm:w-9 md:w-10 rounded-full mx-auto' src={tttlogo} />
                        <p className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>
                            Tubby Turtles is one of the few NFT projects on Energy Web. 10% of the minting costs of each turtle have been and will be donated to <a href='https://teamseas.org/' rel='noreferrer' className='text-blue-500 hover:text-purple-500'>#teamseas</a> <img className='w-5 h-5 inline' src={teamseas} /> (raised about a combined total of $890 so far).
                            <br/>
                            When you stake a Tubby Turtle you will be rewarded with SeaShell (SHL) tokens, the rarity of your turtle will affect the amount of SHL you earn per day.
                        </p>
                        <img className='aspect-square w-8 sm:w-9 md:w-10 rounded-full mx-auto' src={shellcoin} />
                        <p className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>
                            The SeaShell token is the reward, governance and utility token of the Tubby Turtles project.
                            <br/>
                            The token enables you to stake your turtle and also to change your own turtle's names to personalise them. 
                            <br/>
                            It costs 15 000 SHL to stake a turtle.
                        </p>
                        <p className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>Read more about Tubby Turtles and SeaShell at: <a className='text-blue-500 hover:text-purple-500' href='https://tubbyturtles.com' rel='noreferrer' target='_blank'>Tubby Turtles site</a></p>
                    </div>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>Disclaimer: this airdrop is not associated with the Energy Web Foundation (EWF), this is just a community created airdrop organized by a community created NFT project deployed on EWC.</h2>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>You can only register once for the airdrop, registering multiple times won't get you a second airdrop, also if you don't have the gold staker VC then you won't receive the airdrop.</h2>
                    <h2 className='mb-1 sm:mb-2 md:mb-3 text-base md:text-lg lg:text-xl text-center'>The airdrop can still be claimed by <span className='font-bold'>{String(shlContract1)}</span> more gold stakers.</h2>
                    <h2 className='text-base md:text-lg lg:text-xl text-center'>You can view the verified airdrop register smart contract on the Energy Web explorer: <a 
                        className='text-blue-500 hover:text-purple-500' href='https://explorer.energyweb.org/address/0x024b2539111Cc805490f6188e2CeF83D7E1FE161/contracts#address-tabs' rel='noreferrer' target='_blank'>Airdrop register contract</a>
                    </h2>
                    <button className='text-base md:text-lg lg:text-xl text-center mt-2 mb-6 mx-auto hover:brightness-110 bg-blue-300 flex flex-row px-4 py-2 rounded-xl' onClick={addTokenFunction}>Add SeaShell (SHL) to Metamask<img className='ml-1 w-6 aspect square' src={shellcoin} /></button>

                    {braveShieldError === false ? ( 
                        <></>
                    ) : (
                        <div className='text-[rgba(230,110,110)] mb-5 sm:mb-7 px-4 sm:px-8 md:px-16 text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Your shield is blocking the requests to gather some of the data. You can take your Brave browser shield down🙂. There are no ads or trackers here.</div>
                    )}
                    <div className='flex items-center justify-center mx-auto gradientAnimate rounded-[2rem] md:rounded-[2.5rem] w-[95%] sm:w-[90%] md:w-[85%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%] 3xl:w-[40%] aspect-[5/5]'>
                        <div className='flex flex-col relative z-[99] py-5 px-3 sm:py-6 sm:px-4 md:py-7 md:px-5'>
                            <div className='flex flex-col'>
                                <div className='flex flex-col mx-auto'>
                                    <h1 className='3xs:text-xs 2xs:text-sm xs:text-base text-lg md:text-xl lg:text-2xl text-center font-semibold'>Verified credential</h1>
                                    <div className='flex flex-row mx-auto'>
                                        <img className='3xs:h-5 3xs:w-54 2xs:h-6 2xs:w-6 xs:h-7 xs:w-7 s:h-10 s:w-10 h-14 w-14 mx-2' src={Goldstakers} alt="" />
                                    </div>
                                </div>
                                <div className='flex flex-col mx-auto'>
                                    <h1 className='3xs:text-xs 2xs:text-sm xs:text-base text-lg md:text-xl lg:text-2xl text-center font-semibold'>Airdrop reward</h1>
                                    <h2 className='flex flex-row mx-auto 3xs:text-xs 2xs:text-sm xs:text-base text-lg md:text-xl lg:text-2xl text-center font-semibold'><span>10.000</span><img className='ml-2 3xs:h-4 3xs:w-4 2xs:h-6 2xs:w-6 xs:h-7 xs:w-7 s:h-8 s:w-8 h-9 w-9' src={shellcoin} alt="" /></h2>
                                </div>
                            <div>
                                <div className='flex flex-col mx-auto mb-1'>
                                    
                                </div>
                                    <button className='active:translate-y-[3px] my-1 2xs:my-[3px] 3xs:my-[2px] flex flex-row mx-auto bg-[#5d9c57] hover:brightness-110 rounded-xl sm:rounded-2xl md:rounded-2xl p-1 sm:p-2 md:p-3'>
                                        <h1 className='2xs:text-xs xs:text-sm text-base md:text-lg lg:text-xl text-center font-semibold flex my-auto'>Airdrop ended</h1>
                                        <div className='flex flex-row mx-auto'>
                                            <img className='ml-2 2xs:h-5 2xs:w-5 xs:h-6 xs:w-6 h-7 w-7' src={shellcoin} alt="" />
                                        </div>
                                    </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default Home;